import styled from 'styled-components';
import { color } from '../../../constants/theme';

export const Container = styled.nav`
  display: none;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  opacity: ${props => (props.open ? '1' : '0')};
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
  background: ${color.secondaryBg};
  transition: visibility 0s linear ${props => (props.open ? '0s' : '300ms')}, opacity 300ms;
  z-index: 1;
  padding-top: 140px;
  @media (max-width: 896px) {
    display: flex;
    flex-direction: column;
    position: fixed;
    overflow: scroll;
    padding-bottom: 20px;
  }
  ul {
    color: ${color.black};
    font-size: 20px;
    line-height: 30px;
    display: none;
    flex-direction: column;
    list-style: none;
    padding: 0px 30px;

    @media (max-width: 896px) {
      display: flex;
    }

    li {
      margin-bottom: 24px;
    }

    a {
      text-decoration: none;

      &:hover {
        color: inherit;
      }
    }
  }
`;
