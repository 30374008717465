import firebase from 'firebase';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import {
  Container,
  Input,
  Message,
  SubmitButton,
  Form,
  Arrow,
} from './newsletterInput.css';

const NewsletterInput = ({ postUrl, className }) => {
  const [email, setEmail] = useState('');

  return (
    <MailchimpSubscribe
      url={postUrl}
      render={({ subscribe, status, message }) => {
        const handleSubmit = e => {
          e.preventDefault();
          subscribe({ EMAIL: email });
          firebase.analytics().logEvent('newsletter_email_submitted');
        };

        const isError = status === 'error';
        const isSubscribed = status === 'success';

        return (
          <Container className={className}>
            <Form onSubmit={handleSubmit}>
              <Input
                placeholder={'Enter email address'}
                onChange={e => setEmail(e.target.value)}
                required
                value={email}
                type={'email'}
                disabled={isSubscribed}
              />
              <SubmitButton type="submit" disabled={isSubscribed}>
                <Arrow />
              </SubmitButton>
            </Form>
            {isError && (
              <Message dangerouslySetInnerHTML={{ __html: message }} />
            )}
            {isSubscribed && <Message>You are now subscribed.</Message>}
          </Container>
        );
      }}
    />
  );
};

NewsletterInput.propTypes = {
  postUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default NewsletterInput;
