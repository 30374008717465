import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ContentWrapper } from '../../global.css';
import {
  Container,
  Title,
  Subtitle,
  Copyrights,
  FacebookIcon,
  InstagramIcon,
  SocialLink,
  TwitterIcon,
  Link,
  Links,
  LinkSeparator,
  SocialLinks,
  Content,
  NewsletterContainer,
  NewsletterInput,
} from './footer.css';

const query = graphql`
  query FooterQuery {
    footerJson {
      newsletterTitle
      newsletterSubtitle
      instagramUrl
      facebookUrl
      twitterUrl
      copyrights
      privacyLink {
        url
        label
      }
      termsLink {
        url
        label
      }
    }
  }
`;

const Footer = () => {
  const data = useStaticQuery(query).footerJson;

  return (
    <Container>
      <ContentWrapper>
        <Content>
          <Title>{data.newsletterTitle}</Title>
          <Subtitle>{data.newsletterSubtitle}</Subtitle>
          <NewsletterContainer>
            <NewsletterInput
              postUrl={
                'https://hello-sunshine.us14.list-manage.com/subscribe/post?u=448d5f153debbfa199a16277c&amp;id=edd3b65a3d'
              }
            />
          </NewsletterContainer>

          <SocialLinks>
            <SocialLink href={data.instagramUrl} aria-label="Instagram">
              <InstagramIcon />
            </SocialLink>

            <SocialLink href={data.facebookUrl} aria-label="Facebook">
              <FacebookIcon />
            </SocialLink>

            <SocialLink href={data.twitterUrl} aria-label="Twitter">
              <TwitterIcon />
            </SocialLink>
          </SocialLinks>

          <Copyrights>{data.copyrights}</Copyrights>

          <Links>
            <Link to={data.privacyLink.url}>{data.privacyLink.label}</Link>
            <LinkSeparator aria-hidden={true}/>
            <Link to={data.termsLink.url}>{data.termsLink.label}</Link>
          </Links>
        </Content>
      </ContentWrapper>
    </Container>
  );
};

export default Footer;
