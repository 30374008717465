import styled from 'styled-components';
import { color } from '../../../constants/theme';

export const Container = styled.nav`
  ul {
    color: ${color.black};
    font-size: 16px;
    line-height: 20px;
    display: flex;
    list-style: none;
    padding: 0;

    @media (max-width: 896px) {
      display: none;
    }

    li + li {
      margin-left: 50px;
    }

    a {
      text-decoration: none;

      &:hover {
        color: inherit;
      }
    }
  }
`;
