import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { Container } from './menu.css';

const query = graphql`
  {
    headerJson {
      menuItems {
        url
        label
      }
    }
  }
`;

const Menu = ({ open, onOpen }) => {
  const data = useStaticQuery(query).headerJson;

  return (
    <Container open={open}>
      <ul>
        {data.menuItems.map(item => (
          <li key={item.url}>
            <a href={item.url} onClick={onOpen}>
              {item.label}
            </a>
          </li>
        ))}
      </ul>
    </Container>
  );
};

Menu.propTypes = {
  open: PropTypes.bool,
};

export default Menu;
