import styled, { css } from 'styled-components';
import MEDIA from '../../helpers/mediaTemplates';
import LogoSvg from '../../images/logo.svg';
import { color } from '../../constants/theme';

const stickyContainerStyle = css`
  background-color: ${color.background};
  transform: translateY(-10px);
  @media (min-width: 897px) {
    transform: translateY(-60px);
  }
  box-shadow: 0px 30px 30px -10px ${color.shadow};
`;

export const Container = styled.header`
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  position: fixed;
  width: 100%;
  top: 0;
  padding-top: 30px;
  padding-bottom: 20px;
  will-change: transform;
  z-index: 2;
  transition: all 0.25s ease-in-out;
  ${props => (props.open ? `background: ${color.secondaryBg}` : ``)};
  @media (min-width: 897px) {
    padding-top: 80px;
  }

  ${props => (props.isSticky && !props.open ? stickyContainerStyle : '')};
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Logo = styled(LogoSvg)`
  width: 48px;
  height: 48px;
  @media (min-width: 897px) {
    width: 72px;
    height: 72px;
  }
`;

export const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;

  @media (max-width: 897px) {
    display: flex;
  }
`;

export const Hamburger = styled.div`
  background-color: #111;
  width: 26px;
  height: 1.5px;
  border-radius: 1.5px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? 'rotate(-45deg)' : 'inherit')};

  ::before,
  ::after {
    width: 26px;
    height: 1.5px;
    border-radius: 1.5px;
    background-color: #111;
    content: '';
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props => (props.open ? 'rotate(-90deg) translate(-6px, 0px)' : 'rotate(0deg)')};
    top: -6px;
  }

  ::after {
    opacity: ${props => (props.open ? '0' : '1')};
    transform: ${props => (props.open ? 'rotate(90deg) ' : 'rotate(0deg)')};
    top: 6px;
  }
`;

export const Menu = styled.div`
  display: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  opacity: ${props => (props.open ? '1' : '0')};
  background: ${color.secondaryBg};
  transition: opacity 0.3s linear;
  z-index: 1;
  @media (max-width: 897px) {
    display: flex;
    position: fixed;
  }
`;

export const SrOnly = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;
