import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { color, font } from '../../constants/theme';
import MEDIA from '../../helpers/mediaTemplates';
import FacebookSvg from '../../images/facebook.svg';
import InstagramSvg from '../../images/instagram.svg';
import TwitterSvg from '../../images/twitter.svg';
import NewsletterInputBase from 'components/newsletterInput';

export const Container = styled.div`
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  width: 100%;
  background-color: ${color.yellow};
  color: ${color.black};
  padding-top: 80px;
  padding-bottom: 74px;
  display: grid;

  ${MEDIA.MIN_TABLET`
    padding-top: 77px;
    padding-bottom: 64px;
  `};
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  ${MEDIA.MIN_TABLET`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas:
      'title title'
      'subtitle subtitle'
      'newsletter socials'
      'copyrights links';
  `};
`;

export const Title = styled.h2`
  font-size: 30px;
  line-height: 34px;
  margin-bottom: 10px;
  grid-area: title;
`;

export const Subtitle = styled.h3`
  grid-area: subtitle;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
  font-family: ${font.primary};
`;

export const NewsletterContainer = styled.div`
  margin-bottom: 54px;
  ${MEDIA.MIN_TABLET`
    margin-bottom: 0;
  `};
`;

export const NewsletterInput = styled(NewsletterInputBase)`
  ${MEDIA.MIN_TABLET`
    margin-left: 0;
    text-align: left;
  `};
`;

export const Copyrights = styled.p`
  grid-area: copyrights;
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 14px;
  margin-top: 50px;
`;

export const Links = styled.div`
  grid-area: links;
  font-size: 12px;
  line-height: 14px;

  ${MEDIA.MIN_TABLET`
    text-align: right;
    margin-top: 50px;
  `};
`;

export const Link = styled(GatsbyLink)`
  color: inherit;
  text-decoration: none;
`;

export const LinkSeparator = styled.span`
  margin-left: 14px;
  margin-right: 14px;
  &:after {
    content: '|';
  }
`;

export const SocialLinks = styled.div`
  grid-area: socials;
`;

export const SocialLink = styled.a.attrs({
  target: '__blank',
  rel: 'noopener noreferrer',
})`
  display: inline-flex;
  width: 59px;
  height: 59px;
  background-color: ${color.secondary};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-right: 30px;

  ${MEDIA.MIN_TABLET`
    margin-right: 0;
    margin-left: 24px;
  `};
`;

export const FacebookIcon = styled(FacebookSvg)``;

export const InstagramIcon = styled(InstagramSvg)``;

export const TwitterIcon = styled(TwitterSvg)``;
