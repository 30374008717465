import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, useStaticQuery } from 'gatsby';

import { ContentWrapper } from '../../global.css';
import { Container, Logo, Content, Toggle, Hamburger, SrOnly } from './header.css';
import Nav from 'components/header/nav';
import Menu from 'components/header/menu';
import { useSticky } from './useSticky';

const query = graphql`
  {
    headerJson {
      logo {
        ariaLabel
        role
      }
    }
  }
`;

const Header = ({ minimal }) => {
  const data = useStaticQuery(query).headerJson.logo;

  const [navbarOpen, setNavbarOpen] = useState(false);
  const { isSticky } = useSticky();

  const handleHamburgerClick = () => setNavbarOpen(!navbarOpen);

  const renderMenu = () => (
    <>
      <Nav />
      <Toggle navbarOpen={navbarOpen} onClick={handleHamburgerClick}>
        <Hamburger open={navbarOpen} />
      </Toggle>
    </>
  );

  return (
    <>
      <Container open={navbarOpen} isSticky={isSticky}>
        <ContentWrapper padding={30} maxWidth={1080}>
          <Content>
            <Link to="/">
              <Logo aria-hidden={true} role={data.role} />
              <SrOnly>{data.ariaLabel}</SrOnly>
            </Link>
            {!minimal && renderMenu()}
          </Content>
        </ContentWrapper>
      </Container>
      <Menu open={navbarOpen} onOpen={() => setNavbarOpen(false)} />
    </>
  );
};

Header.propTypes = {
  minimal: PropTypes.bool,
};

export default Header;
