export const color = {
  primary: '#001A5A',
  secondary: '#FCFBF8',
  primaryLight: '#7E8AA9',
  background: '#FCFBF8',
  grey: '#e5e5e5',
  black: '#000000',
  pink: '#E34C71',
  pinkLight: '#FCD5E5',
  primaryCta: '#E34C71',
  secondaryCta: '#002FA4',

  text: '#001A5A',
  textInverted: '#FCFBF8',
  textLight: '#929290',

  green: '#51B08B',
  greedDark: '#006950',
  purple: '#606AE9',
  yellow: '#FFF34B',
  shadow: 'rgba(214, 211, 195, 0.2)',

  blue: '#80B8F6',
  teal: '#DAF1E8',
  lightPurple: '#A7A9F6',
  lightBlue: '#C6EAFA',
  secondaryBg: '#F4F2EA',

  border: '#EEECE0',

  communityPrimary: '#E34C71',
  fromTheClub: '#E34C71',
  readership: '#006950',
  mainCollection: '#FFF34B',
};

export const font = {
  primary: 'Larsseit-Regular',
  primaryBold: 'Larsseit-Medium',
  secondary: 'PlantinStd-BoldCondensed',
};
