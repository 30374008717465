import styled from 'styled-components';
import { color } from '../../constants/theme';
import ArrowIcon from '../../images/arrow.svg';
import MEDIA from '../../helpers/mediaTemplates';

export const Container = styled.div`
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  width: 100%;
  position: relative;
  max-width: 429px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

export const Form = styled.form`
  position: relative;
  width: 100%;
`;

export const SubmitButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  right: 15px;
  top: 0;
  height: 50px;
  width: 50px;
`;

export const Input = styled.input`
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-radius: 25px;
  border: none;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 16px;
  background: white;

  &:disabled {
    opacity: 0.7;
  }

  ::placeholder {
    color: ${color.textLight};
  }
`;

export const Message = styled.p`
  position: relative;
  margin-top: 15px;
  width: 100%;

  ${MEDIA.MIN_TABLET`
    position: absolute;
  `};
`;

export const Arrow = styled(ArrowIcon)``;
