import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Container } from './nav.css';

const query = graphql`
  {
    headerJson {
      menuItems {
        url
        label
      }
    }
  }
`;

const Nav = () => {
  const data = useStaticQuery(query).headerJson;

  return (
    <Container>
      <ul>
        {data.menuItems.map(item => (
          <li key={item.url}>
            <a href={item.url}>{item.label}</a>
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default Nav;
